<template>
    <div>
        <div v-show="validando">
            <h1>Confirmando email</h1>
            <template>
                <div class="text-center">
                    <v-progress-circular
                        indeterminate
                        color="yellow"
                    ></v-progress-circular>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
            userId: 0,
            code: '',
            validando: true
        };
    },
    watch: {
        progress(nuevo, viejo) {
            if (nuevo === false && viejo == true) this.validando = false;
        }
    },
    computed: {
        ...mapState('account', ['progress'])
    },
    methods: {
        ...mapActions('account', ['confirm']),
        otro() {
            this.$router.push('/identity/Login');
        },
        error() {
            this.$router.push('/');
        }
    },
    mounted() {
        this.userId = this.$router.history.current.query.userId;
        this.code = this.$router.history.current.query.code;
        const { userId, code } = this;
        this.confirm({ userId, code });
    }
};
//https://localhost:44356/Identity/Account/ConfirmEmail?userId=8&code=CfDJ8O7qGqTpIyJLq%2FqcKi4oPlXnQEwPYi9T4Qru6%2FqAzvc9rM9pk13At3dG3JJWCHWzdzUE5E4PX2xbY2Cu8d7AOpYh4LyY5bqqwZB12jhF1YgcRPSXJLVEfqtzsYperPYLZj4CzQhzM%2Bd20SV7rsBKetH0nGemt3nVS1%2B1QsCpszXKn6JM49VpEvNE4o5J%2F4lxAg%3D%3D
//https://localhost:44356/#/Identity/ConfirmEmail?userId=8&code=CfDJ8O7qGqTpIyJLq%2FqcKi4oPlXnQEwPYi9T4Qru6%2FqAzvc9rM9pk13At3dG3JJWCHWzdzUE5E4PX2xbY2Cu8d7AOpYh4LyY5bqqwZB12jhF1YgcRPSXJLVEfqtzsYperPYLZj4CzQhzM%2Bd20SV7rsBKetH0nGemt3nVS1%2B1QsCpszXKn6JM49VpEvNE4o5J%2F4lxAg%3D%3D
</script>
